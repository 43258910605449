@import '../../styles/variables';

.camper-card {
  --content-padding: 1.5rem;
  --content-spacing: 1rem;
  display: grid;
  overflow: hidden;
  max-width: 600px;
  position: relative;
  border-radius: 20px;
  background-color: $lighterGray;
  box-shadow: $secondary-shadow;
  &__img {
    height: 280px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &__content {
    padding: 8px;
    display: grid;
    h3 {
      font-size: $fs-500;
      padding-block: 0.5rem;
    }
    p {
      font-size: 16px;
    }
    p:nth-child(3) {
      text-align: left;
      padding: 8px 0 0 12px;
      color: $darkGray;
    }
  }
  &__price {
    position: relative;
    margin-top: 16px;
    bottom: 12px;
    left: 12px;
    text-align: left;
    p:nth-child(1) {
      color: $darkerGray;
    }
    p:nth-child(2) {
      color: $darkGray;
    }
  }
  &__amounts {
    position: absolute;
    left: 0;
    top: 250px;
    background-color: $lighterGray;
    width: 88px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px;
    border-radius: 0 8px 0 0;
  }
}

@media (min-width: 30em) {
  .camper-card {
    &__amount {
      height: 36px;
    }
    transition: box-shadow 1s ease-in-out, transform 1s ease-in-out;

    &:hover,
    &:focus-visible {
      transform: translateY(-2px);
      transform: scale(1.05);
      box-shadow: $primary-shadow;
      .move-to-icon {
        opacity: 1;
        transform: scale(1.25);
        transform: translateX(8px);
      }
    }
  }
}

@media (min-width: 50em) {
  .camper-card__content {
    --content-padding: 2rem;
    grid-template-columns: 1fr;
  }
  .camper-card__amounts {
    left: 0px;
    top: 250px;
    border-radius: 0px 16px 0px 0px;
    width: 88px;

    flex-direction: row;
  }
}

@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
