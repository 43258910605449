.star-rating {
  label {
    display: block;
    position: relative;
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    // display: none;
  }
}
