@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import '/src/styles/resets.scss';
@import '/src/styles/variables';
@import '/src/styles/layouts.scss';
@import '/src/styles/typographic.scss';

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f0f0f0;
  color: $darkGray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Domine', serif;
  color: $darkerGray;
  color: $clr-dark-blue;
}

h1 {
  font-size: 56px;
}

h2 {
  font-size: 32px;
}

img {
  display: block;
  height: 100%;
}

.App {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.main-containers {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .flex-around {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
  }
}

.btn-outline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 320px;
  margin-top: 32px;
  font-family: 'Domine', serif;
  text-decoration: none;
  color: $darkerGray;
  font-weight: 700;
  border-radius: 16px;
  padding: 8px;
  cursor: pointer;
  box-shadow: $secondary-shadow;
  transition: all 0.3s ease-out 0s;
  background-color: $secondaryOrange;
  color: $lightGray;
  &:hover {
    box-shadow: $primary-shadow;
    background-color: $orangeMain;
    color: $lighterGray;
  }
  &__go-to {
    &::after {
      content: url('https://api.iconify.design/ic/round-read-more.svg?color=%23f9f9f9&width=24&height=24');
      position: absolute;
      top: 13px;
      height: 20px;
      width: 20px;
      right: 100px;
      transition: all 0.3s ease-out 0s;
    }
  }
}

.hero_image {
  height: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.page-container {
  width: 100%;
  position: relative;
  top: 72px;
  left: 0;
  &__heading {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin: 20px;
  }
  &__description {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    margin: 16px;
  }
}

// .back_icon {
//   background-image: url("./assets/icons/back_icon.png");
//   width: 36px;
//   height: 36px;
//   background-position: center;
//   border-radius: 50%;
//   overflow: hidden;
//   box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
// }

.flex-rows_two {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.center-text {
  text-align: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 280px;
}
