sup {
  vertical-align: super;
  font-size: smaller;
}

.underline-header {
  font-size: $fs-700;
  text-align: center;
  margin-bottom: 32px;
  font-weight: 800;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: -16px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 75%;
    height: 2px;
    opacity: 0.8;
    background: linear-gradient(
      90deg,
      rgba(240, 240, 240, 1) 0%,
      rgba(255, 159, 38, 1) 50%,
      rgba(240, 240, 240, 1) 100%
    );
  }
}

@media (min-width: 40em) {
  .underline-header {
    margin-bottom: $size-700;
    font-size: $fs-800;
  }
}
.underline-header-dark {
  text-align: center;
  margin-bottom: 32px;
  font-weight: 800;
  position: relative;
  &:after {
    transition: all 0.75s ease-in-out;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    margin: auto;
    width: 75%;
    height: 2px;
    content: '.';
    color: transparent;
    background: rgb(38, 38, 38);
    background: linear-gradient(
      90deg,
      rgba(38, 38, 38, 1) 0%,
      rgba(255, 159, 38, 1) 50%,
      rgba(38, 38, 38, 1) 100%
    );
  }
}

.subtitle {
  text-align: center;
  color: $darkGray;
  margin-bottom: 24px;
  font-size: $fs-500;
}

.list-section {
  h3,
  h4 {
    margin-bottom: 16px;
    font-weight: bold;
  }
  ul {
    margin-left: 16px;
  }
}

.small-text {
  font-size: 12px;
  line-height: 1.2;
  padding-left: 8px;
}

.text-small {
  font-size: 0.75rem;
}

.white-text {
  color: $lightGray;
  opacity: 0.9;
}

.widget-text-link {
  cursor: pointer;
  font-weight: 500;
  color: $orangeMain;
}

.list-with-marker {
  padding: 8px 24px;
  li {
    line-height: 1.45;
    padding-block: 4px;
    &::marker {
      display: list-item;
      content: counter(list-item) '.  ';
      color: $orange-cta;
      font-weight: bold;
    }
  }
}

.time {
  font-weight: 500;
}
.text-orange {
  color: $orangeMain;
  font-weight: 500;
}

.ts-dblue {
  text-shadow: 0px 0px 4px $clr-dark-blue;
}
