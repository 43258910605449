.content-card {
  margin: 32px 0;
}

.content-card__content {
  padding: 16px 0;
}

.content-card__content-padding-aside-zero {
  padding: 40px 0 32px;
}
