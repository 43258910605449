.sticky {
  margin-top: 16px;
  position: sticky;
  top: 68px;
  margin-inline: auto;
  z-index: 10;
}
.tabpanel-wrapper {
  .underline-header {
    margin-bottom: 32px;
  }
  .page-container__description {
    margin: 4px;
    font-size: 16px;
  }
  .acorditions-list {
    margin: 16px 0 32px;
  }
}

@media (min-width: 50em) {
  .sticky {
    top: 80px;
  }
}
