@import '../../styles//variables';

.camper-benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 4px solid $clr-accent-400;
  background-color: rgba(244, 230, 220, 0.5);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(150%) blur(10px);
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  height: 160px;

  box-shadow: $secondary-shadow;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    padding: 8px;
  }
  &__name {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    user-select: none;

    h3 {
      padding-block: 0.25rem 1rem;
      font-size: 1rem;
      text-align: center;
    }
  }
}

@media (min-width: 50em) {
  .camper-benefit {
    width: 160px;
    &__name {
      width: 80%;
      h3 {
        font-size: 1rem;
      }
    }
  }
}
