.image-slider-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
}

.img-slider-btn {
  all: unset;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0.125rem;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:focus-visible {
    background-color: rgba(0, 0, 0, 0.2);
    animation: squish 200ms ease-in-out;

    > * {
      animation: squish 200ms ease-in-out;
    }
  }
}

// DOTS //

.img-slider-dots {
  margin: 0.5rem auto;
  display: flex;
  justify-content: center;
  gap: 0.25rem;
}

.img-slider-dot-btn {
  all: unset;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 3px solid #e07000;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  opacity: 0.8;

  &__active {
    background-color: #e07000;
    transform: scale(1.25);
    opacity: 1;
  }

  &:hover,
  &:focus-visible {
    opacity: 0.9;

    .img-slider-dot-btn__doted {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      background-color: #e07000;
      border-radius: 50%;
    }
  }
}

.img-slider-dot-btn__doted {
  display: none;
}

@media (min-width: 50em) {
  .img-slider-dots {
    gap: 0.5rem;
  }
  .img-slider-dot-btn {
    width: 1rem;
    height: 1rem;
  }
  .img-slider-btn {
    padding: 1rem;
  }
}

@keyframes squish {
  50% {
    scale: 1.4 0.6;
  }
}

.img-slider-btn:focus-visible,
.img-slider-dot-btn:focus-visible {
  outline: auto;
}
