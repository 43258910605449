@import '../../styles/variables';

.camper__hero-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 12/10;
  padding: 72px 16px 16px;
  position: relative;
}

.camper__title {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: $darker-gray-opacity;
  max-width: 220px;
  max-height: 200px;
  color: $lightGray;
  padding: 16px;
  border-radius: 20px;
  opacity: 0.8;

  h1 {
    font-size: 28px;
    color: $lightGray;
  }
  &-person {
    display: flex;
    gap: 8px;
    &__icon {
      height: 24px;
      width: 24px;
      background: url('https://api.iconify.design/mdi/account-group.svg?color=%23e07000&width=24&height=24');
    }
  }
  &-transmition {
    display: flex;
    gap: 8px;
    &__icon {
      height: 24px;
      width: 24px;
      background: url('https://api.iconify.design/material-symbols/joystick.svg?color=%23e07000&width=24&height=24');
    }
  }
  &-length {
    display: flex;
    gap: 8px;
    &__icon {
      height: 24px;
      width: 24px;
      background: url('https://api.iconify.design/icons8/resize-horizontal.svg?color=%23e07000&width=24&height=24');
    }
  }
}
.car-equipment,
.list-section {
  h3 {
    font-size: $fs-500;
  }
}
@media (min-width: 50em) {
  .camper__hero-section {
    padding: 88px 16px 16px;
    aspect-ratio: 12/6;
  }
  .car-equipment {
    padding-right: 32px;
  }
  .camper__title {
    display: none;
  }
  .camper__title-big {
    position: absolute;
    bottom: 20px;
    margin: auto 0;
    background-color: $darker-gray-opacity;
    max-width: 720px;
    max-height: 120px;
    color: $lightGray;
    padding: 20px 32px;
    border-radius: 20px;
    opacity: 0.8;
    h1 {
      font-size: 28px;
      color: $lightGray;
      margin-bottom: 8px;
    }
    &__content {
      display: flex;
      justify-content: space-around;
    }
  }
}
