section {
  padding: 1rem 0;
}

img {
  display: block;
  max-width: 100%;
}

@media (min-width: 40em) {
  section {
    padding: 2rem 0;
  }
}

.container {
  --max-width: 1110px;
  --padding: 1rem;
  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
}

.split {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 40em) {
  .split {
    flex-direction: row;
  }

  .split-reverse {
    flex-direction: row-reverse;
  }
  /* poniższy zapis dzieli wszystkie elementy potomne tak by miały taki sam rozmiar */
  .split > * {
    flex-basis: 100%;
  }
  /* wszystkie elementy oprócz pierwszego otrzymują margines po lewej stronie*/
  .split > * + * {
    margin-left: 2em;
  }
  .padding-left {
    padding: 16px 32px;
  }
}

@media (max-width: 840px) {
  .split-form {
    flex-direction: column;
  }
  .split-form > * + * {
    margin-left: 0;
  }
}

.text-center {
  text-align: center;
}

.stretch {
  align-items: stretch;
}

.margin32 {
  margin: 32px;
}

.scale-min {
  transform: scale(1.1);
}

.bg-mountains {
  background-image: url('../assets/wave.svg');
  background-size: cover;
}

.grid-advices {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 1000px) {
  .grid-advices {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

.grid-our-cars {
  display: grid;
  grid-template-columns: minmax(0, 600px);
  justify-content: center;
  gap: 32px;
}

@media (min-width: 1000px) {
  .grid-our-cars {
    //for three campers down line code
    // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    gap: 32px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-h {
  display: flex;
  align-items: center;
}

.no-wrap {
  flex-wrap: nowrap;
}

.even-columns {
  display: grid;
  gap: 1rem;
  overflow-wrap: anywhere;
}

@media (min-width: 50em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.list {
  list-style: none;
  margin-left: 0.5rem;
  li {
    margin-bottom: 0.5rem;
  }
}

.list-justify {
  margin-left: 1rem;
  ul {
    padding-block: 0.5rem;
  }
}

.page-wrapper__bg {
  padding-block: 5rem;
  background-size: cover;
  background-position: center;
  h1 {
    font-size: 3rem;
  }
}
