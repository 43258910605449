@import './variables';

.input-group {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
}

.input-group__input {
  text-align: center;
  color: $darkerGray;
  padding: 10px;
  border: none;
  border-radius: 8px;
  outline: 2px solid $gray;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

@media (min-width: 50em) {
  .input-group__input {
    transition: outline 1s ease-in-out;
  }
  .input-group__input:focus {
    outline: 2px solid $orangeMain;
  }
}

.input-group__label {
  position: absolute;
  top: -22px;
  left: 8px;
  // translate: 40px 20px;
  color: $clr-dark-blue;
  font-size: 12px;
  // transition: translate 500ms, scale 500ms;
  font-weight: 500;
}

input[type='text'],
input[type='tel'],
input[type='email'] {
  display: block;
  padding: 16px 10px 10px 40px;
  clear: both;
  font-size: 16px;
}

input[id='name'],
input[id='lastname'],
input[id='firstname'] {
  background: url('https://api.iconify.design/mdi/person-outline.svg?color=%233c4043&width=24&height=24')
    no-repeat;
  background-position: 10px 10px;
}
input[id='phone'] {
  background: url('https://api.iconify.design/mdi/phone.svg?color=%233c4043&width=24&height=24')
    no-repeat;
  background-position: 10px 8px;
}
input[id='email'] {
  background: url('https://api.iconify.design/mdi/email-outline.svg?color=%233c4043&width=24&height=24')
    no-repeat;
  background-position: 10px 8px;
}

input[id='pickup-place'],
input[id='return-place'] {
  background: url('https://api.iconify.design/mdi/map-marker-outline.svg?color=%233c4043&width=24&height=24')
    no-repeat;
  background-position: 10px 9px;
}

// select

.input-group__select {
  position: relative;
  width: 135px;
  height: 40px;
  background-color: #fff;
  border-radius: 8px;
  select {
    border: 0;
    display: block;
    padding: 10px 24px 8px 80px;
    color: $darkGray;
    border-radius: 8px;
    outline: 2px solid $gray;
    width: 100%;
    height: 100%;
    font-size: 16px;
    background: url('https://api.iconify.design/mdi/account-group.svg?color=%23e07000&width=24&height=24')
      no-repeat;
    background-position: 16px 8px;
    transition: outline 1s ease-in-out;

    &:focus {
      outline: 2px solid $orangeMain;
    }
    & + label {
      position: absolute;
      font-size: 12px;
      top: -22px;
      left: 8px;
      color: $clr-dark-blue;
      user-select: none;
      font-weight: 500;
    }
  }
}

input[type='checkbox'] {
  opacity: 0;
  width: 0;
  margin: 0;
}

label[for='terms'],
label[for='rodo'],
label[for='termsService'] {
  display: inline-block;
  margin-top: 10px;
  padding-left: 24px;
  background: url('https://api.iconify.design/mdi/checkbox-blank-outline.svg?color=%233c4043&width=20&height=20')
    no-repeat;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
  span {
    font-size: 14px;
  }
}

input:checked + label[for='terms'],
input:checked + label[for='termsService'],
input:checked + label[for='rodo'] {
  background: url('https://api.iconify.design/mdi/checkbox-marked-outline.svg?color=%23e07000&width=20&height=20')
    no-repeat;
}

.terms-scroll-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid $gray;
  padding: 8px 8px 8px 20px;
  margin-top: 10px;
  border-radius: 4px;
  color: $darkGray;
  font-size: 12px;
  background-color: #fff;
  ul,
  ol {
    padding-left: 0.5rem;
  }
}

.input-group__time {
  position: relative;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  background-color: $white;
  input {
    height: 100%;
    border: none;
    width: 100%;
    outline: none;
    background-color: transparent;
    text-align: center;
    outline: 2px solid $gray;
    border-radius: 8px;
    padding: 8px;
    transition: outline 1s ease-in-out;

    & + label {
      position: absolute;
      font-size: 12px;
      top: -22px;
      color: $clr-dark-blue;
      user-select: none;
      left: 8px;
      font-weight: 500;
    }
    &:focus {
      outline: 2px solid $orangeMain;
    }
    // TO DO: ręczne nadanie valid ponieważ dla time nie jest używane
  }
}
.input-group__date {
  position: relative;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $white;
  border-radius: 8px;
  input {
    height: 100%;
    border: none;
    width: 100%;
    outline: none;
    background-color: transparent;
    text-align: center;
    outline: 2px solid $gray;
    border-radius: 8px;
    padding: 8px;
    transition: outline 1s ease-in-out;

    & + label {
      position: absolute;
      font-size: 12px;
      top: -22px;
      left: 8px;
      color: $clr-dark-blue;
      user-select: none;
      font-weight: 500;
    }
    &:focus {
      outline: 2px solid $orangeMain;
    }
    // TO DO: ręczne nadanie valid ponieważ dla time nie jest używane
  }
}
input:disabled {
  color: $gray;
}

// input:disabled + label {
//   color: $darkGray;
// }

.input-group__error {
  font-size: 0.75rem;
  line-height: 1.25;
  position: absolute;
  bottom: -16px;
  left: 8px;
  color: red;
}

.hint {
  font-size: 0.75rem;
  line-height: 1.25;
  position: absolute;
  bottom: -16px;
  color: white;
}
//widget

.input-group__container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 32px;
  column-gap: 16px;
}
.input-field {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $darkerGray;
}

.input-field__large-width {
  flex: 1 1 100%;
}

.input-field__small-width {
  flex: 1 1 calc(50% - 10px);
}

.input-group__tooltip {
  position: absolute;
  right: -16px;
  top: 0;
}

.textarea__group {
  resize: none;
  width: 100%;
  height: 80px;
  & + label {
    position: absolute;
    font-size: 12px;
    top: -22px;
    color: $clr-dark-blue;
    user-select: none;
    left: 8px;
    font-weight: 500;
  }
}
