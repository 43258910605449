@import './variables';
.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: $secondary-shadow;
  transition: all 0.4s ease-out 0s;
  text-decoration: none;
  border: none;
  font-weight: 500;
  &:hover {
    box-shadow: $primary-shadow;
  }
}

// .btn-small {

//   background-color: #e07a25;

//   color: #fff;
//   text-decoration: none;
// }

.btn-big {
  height: 48px;
  font-size: 18px;
  padding: 15px 30px;
}

.btn-medium {
  height: 40px;
  font-size: 16px;
  padding: 10px 20px;
}

.btn-small {
  height: 32px;
  font-size: 14px;
  padding: 8px 16px;
}

.btn-cta {
  background: $gradient-color;
  color: $lightGray;
  font-family: $logo-font;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0 1em 1em -1em $secondaryOrange;
  &:hover {
  }
}

.btn-primary {
  background: $gradient-color;
  color: $white;
  transition: transform 0.75s 0.125s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.btn-outlined {
  background: $gradient-light-gray;
  color: $darkGray;
}

@media (min-width: 50em) {
  .btn-outlined {
    &:hover {
      color: $orangeMain;
    }
  }
}

// .btn-secondary {
// }

.btn__right-bottom {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 20px;
}

.btn__with-icon {
  padding: 10px 48px 10px 24px;
  &::before {
    content: '';
    position: absolute;
    top: 12px;
    right: 24px;
  }
}

.btn__our-cars {
  &::before {
    content: url('https://api.iconify.design/mdi/car-search-outline.svg?color=white&width=20&height=20');
  }
}

.btn__more-info {
  &::before {
    top: 12px;
    content: url('https://api.iconify.design/ph/list-magnifying-glass-bold.svg?color=white&width=20&height=20');
  }
}

.btn-form {
  // width: 135px;
  width: 100%;
  height: 40px;
}

.btn-backdrop-close {
  border: none;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 16px;
  top: -24px;
  transform: scale(1.5);
}

.btn-unset {
  all: unset;
  cursor: pointer;
}

.btn-copy {
  cursor: copy;
}
