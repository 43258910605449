@import '././../../styles/_variables.scss';

.entry-form {
  opacity: 0.95;
  margin: 0 auto;

  border-radius: 20px;
  background-color: $darker-gray-opacity;
  z-index: 10;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  label,
  .input-group__select select + label,
  .input-group__date input + label,
  .input-group__time input + label {
    color: $white;
  }
  .input-group,
  .input-group__select,
  .input-group__date,
  .input-group__time {
    background-color: rgba(245, 245, 245, 0.95);
  }
  input[type='time'],
  input[type='text'],
  input[type='date'],
  select[name='selectedAmountPerson'] {
    color: $darkerGray;
    outline-color: $clr-accent-300;
  }
  option {
    color: $darkGray;
  }
  .cta-container {
    position: relative;
    &::after {
      position: absolute;
      content: url('https://api.iconify.design/majesticons/calendar-plus.svg?color=%23f9f9f9&width=16&height=16');
      right: -20px;
      top: 3px;
    }
  }
  input[id='pickup-place'],
  input[id='return-place'] {
    background: url('https://api.iconify.design/mdi/map-marker-outline.svg?color=%23515151&width=24&height=24')
      no-repeat;
    background-position: 10px 9px;
  }
  select[name='selectedAmountPerson'] {
    background: url('https://api.iconify.design/mdi/account-group.svg?color=%23515151&width=24&height=24')
      no-repeat;
    background-position: 16px 8px;
  }
}
