@import "../../../styles/variables";

.selected-car-card {
  --content-padding: 1.5rem;
  --content-spacing: 1rem;
  display: grid;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  background-color: $lighterGray;
  box-shadow: $secondary-shadow;
  &__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 320px;
    position: relative;
  }
  &__content {
    display: grid;
    padding: 8px;
  }
  &__details {
    padding: 8px;
    ul {
        padding-left: 16px;
        padding-bottom: 12px;
        font-size: 14px;
    }
  }
  &__title {
    font-size: 1.5rem;
    color: #3c4043;
    margin-bottom: 12px;
  }
  &__price {
    color: $darkGray;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 8px;
    text-align: center;
  }
  &__amounts {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $lightGray;
    width: 88px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px;
    border-radius: 0 8px 0 0;
  }
}

@media (min-width: 700px) {
  .selected-car-card {
    --content-padding: 2rem;
    --content-padding: 2rem;
    grid-template-columns: 1fr 1fr;
    min-height: 320px;
    &__amounts {
      display: none;
    }
  }
}
