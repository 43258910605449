.image-fullscreen__container {
  position: fixed;
  top: 100px;
  left: 0;
  height: 70%;
  width: 100%;
  z-index: 50;
  padding: 1rem;
  margin-inline: auto;
}
