@import '../../../styles/variables';

.booking-form {
  position: relative;
  background: $lightGray;
  box-shadow: $primary-shadow;
  padding: 32px 16px 16px;
  margin: 40px 0;
  border-radius: 20px;
  &__counter-steps {
    position: absolute;
    top: 16px;
    right: 16px;
    opacity: 0.8;
  }
}

.booking-info {
  ul {
    margin: 12px 20px;
  }
}

.terms-agreement {
  h4 {
    margin-top: 8px;
  }
  a {
    font-size: 8px;
    text-decoration: none;
    color: $highlighted-orange;
  }
}
