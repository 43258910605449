@import '../../styles/variables';

.image-link-box {
  position: relative;
  text-decoration: none;
  display: block;
  background-size: cover;
  height: 240px;
  min-width: 200px;
  border-radius: 20px;
  box-shadow: $secondary-shadow;
  overflow: hidden;
  background-position: center;
  &__name {
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    // background-color: $darkGray;
    text-shadow: 0px 0px 4px #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7) 10%,
      rgba(0, 0, 0, 0) 100%
    );
    // opacity: 0.8;
    h3 {
      color: $lightGray;
      text-shadow: 0px 0px 4px $clr-dark-blue;
      font-size: clamp(1.25rem, 2vw, 1.5rem);
    }
  }
}

@media (min-width: 50em) {
  .image-link-box {
    transition: transform 1s;
    &:hover {
      transform: translateY(-16px);
      box-shadow: $primary-shadow;
      // transform: scale(1.05625);

      .image-link-box__name h3 {
      }
    }
  }
}
