@import '../../styles/variables';

.acorditions-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 20px;
  margin-left: auto;
  margin-right: auto;
  margin: 32px 0;
  .accordion-card {
    border-radius: 20px;
    box-shadow: $secondary-shadow;
    border-left-width: 2px;
    background-color: $lightGray;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out 0s;
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 24px;
      font-weight: 600;
      padding: 12px 28px 12px 12px;
      cursor: pointer;
      width: 100%;
      position: relative;
      text-align: left;
      transition: all 0.3s ease-out 0s;
      h3 {
        font-size: 14px;
        margin-right: 20px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 6px;
        height: 0px;
        background-color: $orange-cta;
        transition: all 0.3s ease-out 0s;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.1875rem;
      height: 0;
      background-color: $orange-cta;
      transition: all 0.3s ease-out 0s;
    }
    &.is-open {
      border-color: #fff;
      box-shadow: $primary-shadow;
      border-left-width: 0;
      padding-left: 0.1875rem;
      &::before {
        height: 100%;
      }
    }
    &__content {
      position: relative;
      white-space: pre-line;
      height: 0;
      overflow: hidden;
      transition: height 0.5s ease;
      padding: 0 28px 0 16px;
      color: $darkGray;
      text-align: left;
      &.show {
        height: auto;
      }
      &-container {
        padding-bottom: 16px;
      }
      p,
      li {
        line-height: 1.5;
        font-size: 14px;
      }
      a {
        color: $orangeMain;
        text-decoration: none;
      }
      ul {
        list-style-type: disc;
        padding-left: 14px;
        li {
          line-height: 1.25;
          padding-top: 0.25rem;
        }
      }
    }
  }

  @media (min-width: 50em) {
    .accordion-card {
      &__head {
        padding: 14px 28px 14px 16px;
        h3 {
          font-size: 20px;
        }
      }
      &__content {
        padding: 0 40px 0 20px;
        &-container {
          padding-bottom: 24px;
          p,
          li {
            font-size: 16px;
          }
        }
      }
    }
  }
  // @media (min-width: 1200px){}

  @media only screen and (min-width: 50em) {
    .accordion-card:hover {
      box-shadow: $primary-shadow;
      border-color: #fff;
      border-left-width: 0;
      padding-left: 0.1875rem;
    }
    .accordion-card__head {
      &:hover {
        &::after {
          height: 100%;
        }
        h3 {
          color: $orangeMain;
        }
      }
    }
  }
  span {
    transition: 0.3s;
  }
}
.accordion-card-header__tile {
  width: 90%;
  h3 {
    font-weight: 700;
    font-size: 12px;
    color: $lightGray;
  }
}

.dropdown_arrow {
  position: absolute;
  right: 12px;
  top: 6px;
  &::before {
    display: block;
    transition: all 0.75s;
    margin: auto;
    content: url('https://api.iconify.design/majesticons/chevron-down-line.svg?color=%233c4043&width=32&height=32');
    transform-origin: center;
    height: 32px;
  }
}
.dropdown_arrow_active {
  &::before {
    transform: rotate(-180deg);
  }
}
