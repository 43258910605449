@import '../../../styles/variables';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 40;
  background-color: $darker-gray-opacity;
}
