@import '../../../styles/variables';

.booking-summary {
  h3 {
    font-size: $fs-600;
  }
  h4 {
    font-size: $fs-500;
    font-weight: bold;
  }
  sup {
    color: $highlighted-orange;
  }
}

.booking-summary__card {
}

.table-container {
  display: flex;
  flex-direction: column;
}
.table-section {
  margin: 16px 0;
}

.table-row {
  display: flex;
}

.table-cell {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.table-cell-1 {
  flex: 3;
}

.table-cell-2 {
  flex: 1;
}

.table-cell-3 {
  flex: 1;
}

.table-cell-4 {
  flex: 1;
}

.table-cell__last {
  padding: 8px;
}

.row-grid-acc {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr;
  padding: 4px;
  border-bottom: 1px solid #ccc;
}

.grid-row-seasons {
  display: grid;
  grid-template-columns: 4.5fr 1.5fr 2.25fr 2fr;
  padding: 4px;
  border-bottom: 1px solid #ccc;
}

.grid-row-summary-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 4px;
  border-bottom: 1px solid #ccc;
}

.grid-row-last {
  display: grid;
  grid-template-columns: 8fr 2fr;
  padding: 4px;
  border-bottom: 1px solid #ccc;
}
.grid-row-73 {
  display: grid;
  grid-template-columns: 7fr 3fr;
  padding: 4px;
  margin-top: 4px;
}
.grid-booking-item {
}
