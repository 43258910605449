@import '../../styles/variables';

.slider-container {
  width: 100%;
  position: relative;
  margin: 32px auto;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slide {
  position: relative;
  width: 100%;
  border-radius: 16px;
  user-select: none;
  overflow: hidden;
  height: 400px;
  &-center {
    z-index: 10;
    position: relative;
  }
}
/* .slide-prev {
  z-index: 1;
  height: 350px;
  width: 350px;
  left: 3%;
}

.slide-next {
  z-index: 1;
  height: 350px;
  width: 350px;
  right: 3%;
} */

.slide_brand {
  object-position: center;
  object-fit: contain;
  aspect-ratio: 12/6;
  margin-inline: auto;
}

.all-dots {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}
.dot:hover {
  background-color: $orangeMain;
  opacity: 0.6;
}

.active-dot {
  background-color: $orangeMain;
  &:hover {
    opacity: 1;
  }
}

.slides-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.button {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background-color: #ee4043;
  color: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.button:hover {
  background-color: #e61418;
}

.prev,
.next {
  cursor: pointer;
  z-index: 20;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 30px;
  font-weight: bold;
  border-radius: 5px 5px 5px 5px;
  color: $orangeMain;
  user-select: none;
}

.prev {
  left: 0px;
}

.next {
  right: 0px;
}

.arrows {
  display: block;
  .prev,
  .next {
    background-color: $orangeMain;
    opacity: 0.5;
    color: white;
  }
}

.prev:hover,
.next:hover {
  transition: all 0.5s ease-in;
  opacity: 1;
}

@media (min-width: 50em) {
  .slide {
    height: 640px;
  }
}
