@import '../../../../styles/variables';

.bottom-nav {
  position: fixed;
  bottom: 0;
  display: none;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: $darker-gray-opacity;
  &__list {
    list-style: none;
  }
}
