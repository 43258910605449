@import '../../../styles/variables';

.back-to-top-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 8px;
  background-color: $darkGray;
  box-shadow: $primary-shadow;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 30;
}

.back-to-top-button.visible {
  opacity: 0.8;
  transition: opacity 0.75s ease-in-out;
  &:hover {
    opacity: 0.9;
    // transform: scale(1.125);
    animation: beat 0.5s infinite alternate;
    transform-origin: center;
  }
}

@keyframes beat {
  to {
    transform: scale(1.125);
  }
}
