.camper {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 6px;
  right: -120px;
  opacity: 0.5;
  z-index: 1;
  display: none;
  &-main {
    height: 76px;
    img {
      height: 100%;
    }
  }
}
