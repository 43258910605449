.hero_landing {
  background-image: url('../assets/images/camper_first_org.png');
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  aspect-ratio: 4/2;
  background-repeat: no-repeat;
  padding: 80px 0 32px 0;
}

.hero_landing_second {
  position: relative;
  // background-image: url('../assets/images/campers_group.jpg');
  background-image: url('../assets/images/img-mountains.jpg');

  aspect-ratio: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  width: 100%;
}

@media (min-width: 50em) {
  .hero_landing {
    aspect-ratio: 8/4;
    padding-top: 160px;
    .entry-form {
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .hero_landing_second {
    aspect-ratio: 8/4;
  }
}
