@import '../../styles/variables';

.camper-season-price {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  box-shadow: $secondary-shadow;
  padding: 16px;
  margin-bottom: 24px;
  background: $gradient-light-gray;
  &:not(.shadow-orange) {
    opacity: 0.8;
  }
  &__icon {
    width: 64px;
    height: 64px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    background-size: contain;
    flex: 0 0 72px;
  }
  &__description {
    flex: 1;
    padding-left: 12px;
    h4 {
      font-size: 18px;
      margin-bottom: 4px;
    }
    p {
      color: $darkGray;
      &::first-letter {
        font-size: 1.1rem;
      }
    }
    h5 {
      span {
        color: $orange-cta;
      }
    }
    s {
      font-size: 10px;
      color: $darkGray;
      text-decoration: line-through;
      text-decoration-color: $orangeMain;
      text-decoration-thickness: 1px;
    }
  }
  .list-justify {
    li::marker {
      display: list-item;
      color: #ff8000;
      font-weight: bold;
    }
  }
}

.pricing-brand {
  img {
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: auto 1/1;
    max-height: 588px;
  }
}

.pricing__info {
  margin: 32px 16px 16px;
  h3 {
    margin-bottom: 8px;
  }
  ul {
    margin-left: 20px;
  }
}

.shadow-orange {
  box-shadow: $shadow-cta;
  transform: scaleX(1.025);
  .camper-season-price__description {
    h4 {
      color: $orangeMain;
      font-weight: 600;
      font-size: 1.125rem;
    }
  }
}

.actual-season-label {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px 4px;
  border-radius: 8px;
  background-color: $orangeMain;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  color: $lighterGray;
  opacity: 0.75;
}
