@import './variables';
.padding-block-100 {
  padding-block: $size-100;
}
.padding-block-200 {
  padding-block: $size-200;
}
.padding-block-300 {
  padding-block: $size-300;
}
.padding-block-400 {
  padding-block: $size-400;
}
.padding-block-500 {
  padding-block: $size-500;
}
.padding-block-600 {
  padding-block: $size-600;
}
.padding-block-700 {
  padding-block: $size-700;
}
.padding-block-900 {
  padding-block: $size-900;
}

.margin-bottom-400 {
  margin-block-end: $size-400;
}

.margin-bottom-600 {
  margin-block-end: $size-600;
}

.gap-400 {
  gap: $size-400;
}
.gap-600 {
  gap: $size-600;
}

.opacity-400 {
  opacity: 0.75;
}

.sliding-tabs-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  position: relative;
  background: $gradient-white;
  box-shadow: $secondary-shadow;
  padding: 8px;
  border-radius: 20px;
  & * {
    z-index: 2;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 12px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    text-align: center;
  }

  input[type='radio'] {
    display: none;
  }
  input[type='radio']:checked + label {
    color: $lighterGray;
  }

  input[id='radio-0']:checked ~ .glider {
    transform: translateX(0);
  }

  input[id='radio-1']:checked ~ .glider {
    transform: translateX(100%);
  }

  input[id='radio-2']:checked ~ .glider {
    transform: translateX(200%);
  }
  input[id='radio-3']:checked ~ .glider {
    transform: translateX(300%);
  }

  .glider {
    position: absolute;
    display: flex;
    height: 40px;
    background: $gradient-color;
    z-index: 1;
    border-radius: 12px;
    transition: 0.25s ease-out;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    left: 0px;
    right: 0px;
  }
}

// @media (min-width: 80em) {
//   .glider {
//     padding-block: 24px;
//   }
// }

.pad-left-300 {
  padding-left: 0.5rem;
}
.pad-left-400 {
  padding-left: 1rem;
}

.clr-text-dblue {
  color: $clr-dark-blue;
}

.underline-bottom {
  text-decoration: underline;
  text-decoration-color: $orangeMain;
  font-weight: bold;
}

a.underline-bottom {
  cursor: pointer;
}
