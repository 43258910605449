$orangeMain: #e07000;
$clrOrangeMainOpacity: rgba(224, 112, 0, 0.7);
$orange-cta: #ff8000;
$secondaryOrange: #c86400;
$highlighted-orange: #ff9900;
$darker-gray-opacity: rgba(38, 38, 38, 0.9);
$darkerGray: #262626;
$clr-accent-300: #515151;
$clr-accent-400: #3c3c3c;

$clr-accent-500: #262626;
$clr-dark-blue: #002243;
$darkGray: #515151;
$clr-tr095-darkgray: rgba(60, 60, 60, 1);
$gray: #c7c7c7;
$lighterGray: #f5f5f5;
$lightGray: #f9f9f9;
$bg-color: #f0f0f0;
$white: #fff;
$gradient-orange-secondary: linear-gradient(
  0deg,
  rgba(202, 101, 0, 1) 25%,
  rgba(224, 112, 0, 1) 50%,
  rgba(227, 126, 26, 1) 75%
);
$gradient-orange-cta: linear-gradient(
  0deg,
  rgba(255, 128, 0, 1) 0%,
  rgba(255, 141, 26, 1) 25%,
  rgba(255, 153, 51, 1) 50%,
  rgba(255, 166, 77, 1) 75%
);
$gradient-color: linear-gradient(
  0deg,
  rgba(224, 112, 0, 1) 0%,
  rgba(227, 126, 26, 1) 35%,
  rgba(230, 141, 51, 1) 70%
);
$gradient-light-gray: linear-gradient(
  0deg,
  rgba(249, 249, 249, 1) 0%,
  rgba(250, 250, 250, 1) 25%,
  rgba(251, 251, 251, 1) 50%,
  rgba(252, 252, 252, 1) 75%
);
$gradient-white: linear-gradient(
  0deg,
  rgba(251, 251, 251, 1) 0%,
  rgba(253, 253, 253, 1) 35%,
  rgba(255, 255, 255, 1) 70%
);
$gradient-dark-gray: linear-gradient(
  0deg,
  rgba(38, 38, 38, 1) 25%,
  rgba(34, 34, 34, 1) 50%,
  rgba(30, 30, 30, 1) 75%
);
$gradient-orange-cta-new: linear-gradient(#ff9f2f, #ff6026);
$primary-shadow: 0px 16px 32px 0px rgb(19 49 94 / 24%);
$secondary-shadow: 0px 8px 16px 0px rgb(19 49 94 / 16%);
$shadow-cta: 0px 16px 32px 0px rgb(255 128 0 / 16%);

$domine-font: 'Domine', serif;
$logo-font: 'Orbitron', sans-serif;
// @media (min-width: 576px) {
// }
// @media (min-width: 768px){}
// @media (min-width: 992px){}
// @media (min-width: 1200px){}

// sizes
$size-100: 0.25rem;
$size-200: 0.5rem;
$size-300: 0.75rem;
$size-400: 1rem;
$size-500: 1.5rem;
$size-600: 2rem;
$size-700: 3rem;
$size-800: 4rem;
$size-900: 5rem;

// fonts-sizes
$fs-300: 0.75rem; //12px
$fs-400: 1rem; //16px
$fs-500: 1.25rem; //20px
$fs-600: 1.5rem; //24px
$fs-700: 2rem; //32px
$fs-800: 2.5rem; //40px
$fs-900: 3rem; //48px
