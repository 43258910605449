@import '../../../styles/variables.scss';
.booking-form__counter-steps-icons {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 1rem 0;
  gap: 4px;
}

.booking-form__counter-steps-icon {
  width: 64px;
  height: 64px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.booking-form__counter-steps__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.booking-form__counter-steps__content {
  display: none;
}
.booking-form__counter-steps__title {
  font-weight: 500;
  font-size: 14px;
  color: $darkerGray;
}
.booking-form__counter-steps__subtitle {
  font-size: 12px;
}

@media (min-width: 40em) {
  .booking-form {
    &__counter-steps-icons {
      gap: 32px;
    }
    &__counter-steps__content {
      display: block;
      text-align: center;
      margin-top: 4px;
    }
  }
  .booking-form__counter-steps-icon {
    width: 80px;
    height: 80px;
  }
}

.booking-form__counter-steps__container-active {
  .booking-form__counter-steps-icon {
    outline: 1px solid $orange-cta;
    border-radius: 50%;
    outline-offset: 2px;
    outline-style: dashed;
  }
  .booking-form__counter-steps__title {
    color: $orangeMain;
  }
  .booking-form__counter-steps__subtitle {
    color: $darkerGray;
  }
}
