@import '../../../styles/variables';
.accessories-form-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.accessories-radio {
  position: relative;
  box-shadow: $secondary-shadow;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  max-width: 560px;
  border-radius: 8px;
  background: $gradient-light-gray;
}

.accessories-radio__header {
  display: flex;
  align-items: center;
  position: relative;
  &-name {
    font-size: 1rem;
  }
  &-icon {
    width: 32px;
    height: 32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
  }
}

.accessories-radio__body {
  align-items: self-start;
  display: flex;
  flex-direction: column;
  justify-content: start;
  &-slider {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    filter: drop-shadow(0 8px 16px rgba(19, 49, 94, 0.16));
    &__item {
      height: 40px;
      width: auto;
      position: relative;
      background-image: $gradient-light-gray;
      &-radio {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        margin: 0;
        cursor: pointer;
      }

      &-radio:checked + .radio-tile {
        background: $gradient-color;
        transform: scale(1.1, 1.1);
        z-index: 1;
        position: relative;
        .radio-tile-label {
          color: white;
          background: $gradient-color;
          h3 {
            color: $lighterGray;
          }
          p {
            color: $lightGray;
          }
        }
      }
      &-radio:checked + .radio-tile__default {
        background: $gradient-orange-secondary;
        .radio-tile-label {
          background: transparent;

          h3 {
            color: $lightGray;
          }
        }
      }
    }
    &__item:first-of-type {
      border-radius: 8px 0 0 8px;
    }
    &__item:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }
}

//default tile styles
.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // border: 2px solid $orange-cta;
  border-radius: 8px;
  transition: transform 300ms ease;
}

.radio-tile-label {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  color: $orange-cta;
  h3 {
    color: $darkerGray;
  }
  p {
    color: $darkGray;
    font-size: 10px;
  }
}

.accessories-radio__info {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 24px;
  width: 24px;
  text-align: center;
  border-radius: 8px;
  user-select: none;
  user-select: none;
  cursor: pointer;
}
.accessories-radio__show-info {
  position: absolute;
  background: $gradient-light-gray;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  padding: 16px;
  p {
    font-size: 14px;
  }
  &__content {
    font-size: 14px;
    height: 70%;
    line-height: 1.25;
    margin: 8px auto 16px;

    overflow-y: scroll;
    padding-right: 8px;
    padding-bottom: 16px;
  }
}

.accessories-radio__body-pricing {
  font-size: 14px;
  margin: 4px;
}

.accessories-radio__show-info__header {
  position: relative;
}

@media (min-width: 50em) {
  .accessories-radio__header {
    &-name {
      margin: 0;
    }
  }

  // .radio-tile:hover .radio-tile-label h3 {
  //   font-size: 32px;
  //   color: $orangeMain;
  // }
}
