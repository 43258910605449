@import '../styles/variables';

.faqs_brand_bottom {
  width: 100%;
  height: 640px;
  background-image: url('../assets/images/faqs/faq_subsite_downbrand.jpg');
  background-size: cover;
  background-position: center;
  margin-top: auto;
  padding: 32px 16px;
  h3 {
    text-align: center;
    font-size: $fs-500;
  }
  .faqs_brand_bottom-description {
    font-size: 1rem;
    color: $clr-dark-blue;
    font-weight: 500;
  }
  .contact-box-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-block: 1.5rem;
    a {
      text-decoration: none;
    }
  }
  .contact-box {
    height: 64px;
    margin-inline: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: minmax(0, 1fr);
    width: 100%;
    padding: 8px;
    border-radius: 16px;
    box-shadow: $secondary-shadow;
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(150%) blur(10px);
    transition: transform 1s 0.1s ease-in-out;
    cursor: pointer;
    &-icon {
      object-fit: contain;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: $clr-dark-blue;
      img {
        object-position: center;
      }
    }
    &-name {
      white-space: pre-line;
      line-height: 1.2;
      text-align: center;
      color: $darkerGray;
      height: 100%;
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
    }
  }
}

@media (min-width: 30em) {
  .faqs_brand_bottom .faqs_brand_bottom-description {
    font-size: 1rem;
    font-weight: 500;
  }
  .faqs_brand_bottom {
    .contact-box-list {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 1rem;
      .contact-box-name {
        p {
          font-size: 1.25rem;
        }
      }
      .contact-box {
        &:hover,
        &:focus-visible {
          box-shadow: $primary-shadow;
          transform: translateY(4px);
        }
      }
    }
  }
}

@media (min-width: 50em) {
  .faqs_brand_bottom {
    h3 {
      font-size: $fs-600;
      line-height: 1.25;
    }
    .contact-box-list {
      grid-template-columns: repeat(4, 1fr);
      gap: 3rem;
      padding-block: 3rem;
      .contact-box {
        gap: 1rem;
        height: 200px;
        grid-template-columns: 1fr;
        grid-template-rows: minmax(0, 1.5fr) minmax(0, 1fr);

        &-name {
          align-items: start;
          justify-content: center;
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
