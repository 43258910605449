@import '../../styles/variables';

.link-box-with-photo {
  position: relative;
  --content-padding: 1.5rem;
  --content-spacing: 1rem;
  display: grid;
  border-radius: 20px;
  overflow: hidden;
  max-width: 550px;
  text-decoration: none;
  border-radius: 20px;
  background: $gradient-light-gray;
  box-shadow: $secondary-shadow;
  margin-inline: auto;
  &__img {
    min-height: 240px;
    overflow: hidden;
  }
  &__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
  }
  &__content {
    padding: 12px;
    white-space: pre-line;
    position: relative;
    z-index: 2;
    background: $gradient-light-gray;
    transition: border-radius 1s ease-in-out, box-shadow 1s ease-in-out;

    p {
      font-size: 16px;
      color: #3c4043;
    }
  }
  &__title {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
}

@media (min-width: 550px) {
  .link-box-with-photo {
    --content-padding: 2rem;
    grid-template-columns: 1fr 1fr;
    min-height: 320px;
  }
}

@media (min-width: 50em) {
  .link-box-with-photo {
    transition: box-shadow 1s ease-in-out, transform 1s ease-in-out;
    &__img {
      transition: transform 1s ease-in-out;
    }
    &:hover {
      transform: translateY(-2px);
      transform: scale(1.05);
      // transform: translateX(32px);
      box-shadow: $primary-shadow;
      .link-box-with-photo__img {
        transform: scale(1.125);
      }
      .link-box-with-photo__content {
        border-radius: 20px;
        box-shadow: $secondary-shadow;
      }
      .move-to-icon {
        opacity: 1;
        transform: scale(1.25);
        transform: translateX(8px);
        z-index: 3;
      }
    }
  }
}
