@import '../../styles/variables';

.advice-item {
  margin: 16px 0;
  overflow: hidden;
  opacity: 0.4;
  transition: transform 2s ease-in, opacity 1.5s 0.5s ease-in-out;

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 56px;
    aspect-ratio: 8/4;
    border-radius: 8px;
    transform: translateX(100%);

    transition: transform 2s ease-in;
  }
  &__info {
    padding: 24px 8px;
    text-align: center;
    white-space: pre-line;
    h3 {
      margin-bottom: 16px;
      font-size: $fs-600;
    }
    p {
      color: $darkGray;
      text-align: left;
    }
    li {
      text-align: left;
      margin-left: 1rem;
    }
  }
}

@media (min-width: 50em) {
  .advice-item {
    margin: 2.5rem auto 1rem;
    &__image {
      border-radius: 16px;
      background-size: cover;
      margin: 0 auto;
      aspect-ratio: 8/3;
    }
    &__info {
      padding: 24px 16px;
      h3 {
        font-size: $fs-700;
      }
    }
  }
}

.advice-section__description {
  white-space: pre-line;
}

.advice-item:nth-of-type(2n) {
  .advice-item__image {
    transform: translateX(-100%);
  }
}

.is-visible,
.is-visible:nth-of-type(2n) {
  opacity: 1;
  .advice-item__image {
    transform: translateX(0);
  }
}
