@import '../../../../styles/variables';

.page-wrapper {
  margin-top: 72px;
  &__heading {
    text-align: center;
    margin: 16px 0 32px;
    font-weight: 800;
    position: relative;
    font-size: 32px;
    &::before {
      content: '';
      position: absolute;
      bottom: -16px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 75%;
      height: 2px;
      background: rgb(240, 240, 240);
      background: linear-gradient(
        90deg,
        rgba(240, 240, 240, 1) 0%,
        rgba(255, 159, 38, 1) 50%,
        rgba(240, 240, 240, 1) 100%
      );
    }
  }
}

.page-wrapper_with_back {
  margin: 72px 8px 36px;
  width: 100%;
  &__heading {
    font-size: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 20px 0;
  }
  &__content {
    margin: 20px auto 0;
    font-size: 16px;
    font-weight: 700;
    color: #3c4043;
    p {
      margin: 8px 12px;
      font-weight: 400;
    }
  }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .page-wrapper_with_back {
    &__content {
      font-size: 20px;
      p {
        font-size: 16px;
      }
    }
  }
}
@media (min-width: 992px) {
  .page-wrapper_with_back {
    margin: 72px auto 0;
    width: 880px;
    &__content {
      font-size: 20px;
      p {
        font-size: 16px;
      }
    }
  }
}
// @media (min-width: 1200px) {}

.page-wrapper_image_header {
  &__brand {
    background-position: center;
    background-size: cover;
    aspect-ratio: 4.5/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    padding: 16px;
    h1 {
      text-align: center;
      margin-top: 64px;
      text-shadow: 0px 0px 4px $darkerGray;
      color: $lighterGray;
      user-select: none;
      font-size: 40px;
      font-weight: 800;
    }
  }
}

@media (min-width: 576px) {
  .page-wrapper_image_header {
    width: 100%;
    &__brand {
      h1 {
        font-size: 48px;
      }
    }
  }
}
@media (min-width: 768px) {
  .page-wrapper_image_header {
    width: 100%;
    &__brand {
      background-position: center;
    }
  }
}
// @media (min-width: 992px){}
// @media (min-width: 1200px){}
