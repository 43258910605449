@import '../../../styles/variables';

.select-car-container {
  width: 100%;
}
.sliding-tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  position: relative;
  background: $gradient-white;
  box-shadow: $secondary-shadow;
  padding: 8px;
  border-radius: 20px;
  & * {
    z-index: 2;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 12px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    text-align: center;
  }

  input[type='radio'] {
    display: none;
  }
  input[type='radio']:checked + label {
    color: $lighterGray;
  }

  input[id='radio-0']:checked ~ .glider {
    transform: translateX(0);
  }

  input[id='radio-1']:checked ~ .glider {
    transform: translateX(100%);
  }

  input[id='radio-2']:checked ~ .glider {
    transform: translateX(200%);
  }
  input[id='radio-3']:checked ~ .glider {
    transform: translateX(300%);
  }
  .glider {
    position: absolute;
    display: flex;
    height: 100%;
    background: $gradient-color;
    z-index: 1;
    border-radius: 12px;
    transition: 0.25s ease-out;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    left: 0px;
    right: 0px;
  }
}

.select-car__placeholder {
  height: 420px;
  box-shadow: $secondary-shadow;
  border-radius: 20px;
  text-align: center;
  padding: 32px 16px;
  h3 {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  &-icon {
    background: url('https://api.iconify.design/mdi/campervan.svg?color=%23e07000&width=240&height=240')
      no-repeat center center / contain;
    height: 240px;
    width: 240px;
  }
}

@media (min-width: 50em) {
  .sliding-tabs {
    border-radius: 20px;
    &__tab {
      font-size: 16px;
      &:hover {
        color: $orangeMain;
      }
    }
    .glider {
      height: 40px;
    }
  }
}
