@import './variables';
.icon-transmition {
  height: 24px;
  width: 24px;
  background: url('https://api.iconify.design/material-symbols/joystick.svg?color=%23e07000&width=24&height=24');
}

.icon-bed {
  height: 24px;
  width: 24px;
  background: url('https://api.iconify.design/tabler/bed-filled.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}
.icon-home {
  background: url('https://api.iconify.design/ic/round-home.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}

.icon-24 {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-32 {
  height: 32px;
  width: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ic-rounded-orange {
  border: 3px dotted $orangeMain;
  border-radius: 50%;
  padding: 0.1125rem;
}

.icon-image {
  background: url('https://api.iconify.design/majesticons/image-line.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}

.icon-close {
  background: url('https://api.iconify.design/majesticons/close.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}
