@import '../../styles/variables';

.benefits_our_campers {
  position: relative;
  border-radius: 20px;
  padding: 2rem 1rem;
  opacity: 0.9;
  &__list {
    max-width: 560px;
    margin-inline: auto;

    grid-gap: 32px;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}
.label-bg {
  width: 16%;
  height: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  border-radius: 8px;
  box-shadow: $secondary-shadow;
}
@media (min-width: 50em) {
  .benefits_our_campers__list {
    grid-template-columns: repeat(3, 1fr);
    .btn__with-icon {
      padding: 10px 56px 10px 32px;
    }
  }
}
