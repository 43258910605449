@import '../../../styles/variables';

.booking-info {
  h3 {
    font-size: $fs-500;
  }
  ol {
    padding: 8px 24px;
    li {
      line-height: 1.45;
      padding-block: 4px;
      &::marker {
        display: list-item;
        content: counter(list-item) '. ';
        color: $orange-cta;
        font-weight: bold;
      }
    }
  }
}

@media (min-width: 50em) {
  .booking-info {
    h3 {
      font-size: $fs-600;
    }
  }
}
