@import '../../../styles/variables';

.footer {
  background-color: $darkerGray;
  color: $lightGray;
  padding-block: 32px;
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }

  &__column {
    h4 {
      font-size: 20px;
      color: $lighterGray;
      text-transform: capitalize;
      margin-bottom: 28px;
      font-weight: 700;
      position: relative;
      max-width: 600px;
    }

    h4::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      background-color: $orangeMain;
      height: 2px;
      box-sizing: border-box;
      width: 50px;
    }
    p {
      // text-align: center;
      line-height: 1.1;
    }
    a {
      text-decoration: none;
      color: $lightGray;
      margin: 4px 0;
      &:hover {
        color: $orangeMain;
      }
    }
  }
  &-list {
    &-item {
      display: flex;
      // flex-direction: column;
      align-items: center;
      margin: 8px 0;

      &-icon {
        height: 32px;
        width: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        // margin-bottom: 8px;
        margin-right: 8px;
      }
    }
  }
  small {
    text-align: center;
    font-size: 12px;
    line-height: 1.75;
  }
}

.icon-download {
  background: url('https://api.iconify.design/mdi/folder-arrow-down.svg?color=%23e07000&width=32&height=32');
}
.icon-cookies {
  background: url('https://api.iconify.design/majesticons/cookie.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}
.icon-rent-doc {
  background: url('https://api.iconify.design/majesticons/document-award-line.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}

.icon-location {
  // background-image: url("../../../assets/icons/location-icon.png");
  background: url('https://api.iconify.design/mdi/map-marker-outline.svg?color=%23e07000&width=32&height=32');
}

.icon-phone {
  // background-image: url("../../../assets/icons/phone-icon.png");
  background: url('https://api.iconify.design/mdi/phone.svg?color=%23e07000&width=32&height=32');
}

.icon-message {
  // background-image: url("../../../assets/icons/message-icon.png");
  background: url('https://api.iconify.design/mdi/email-outline.svg?color=%23e07000&width=32&height=32');
}

.icon-download {
  background: url('https://api.iconify.design/mdi/folder-arrow-down.svg?color=%23e07000&width=32&height=32');
}

.icon-web {
  background: url('https://api.iconify.design/majesticons/globe-grid-line.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}
.icon-privacy {
  background: url('https://api.iconify.design/iconoir/privacy-policy.svg?color=%23e07000&width=24&height=24')
    no-repeat center center / contain;
}

.icon-pricing {
  background: url('https://api.iconify.design/fluent/receipt-money-24-regular.svg?color=%23e07000&width=32&height=32')
    no-repeat center center / contain;
}

.icon-rate-us {
  background: url('https://api.iconify.design/mdi/star-check.svg?color=%23e07000&width=32&height=32')
    no-repeat center center / contain;
}

.icon-send-message {
  background: url('https://api.iconify.design/akar-icons/edit.svg?color=%23e07000&width=32&height=32')
    no-repeat center center / contain;
}
.footer-branding {
  opacity: 0.9;
  display: flex;
  align-items: center;
  &__logo {
    background-image: url('../../../assets/ikifriki-logo.svg');
    height: 64px;
    width: 360px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 30em) {
}
@media (min-width: 50em) {
  .footer {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    &-list-item {
      transition: transform 1s ease-in-out, color 1s ease-in-out;

      &:hover {
        transform: translateX(2px);
      }
    }
  }
}
