@import '../../../styles/_variables.scss';

.modal-background {
  width: 100vw;
  height: 100vh;
  background-color: $darker-gray-opacity;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 90%;
  max-width: 640px;
  height: 320px;
  top: 100px;
  margin: 0 auto;
  background: $gradient-light-gray;
  border-radius: 20px;
  overflow: hidden;
  opacity: 1;
  transition: all 0.25s ease-in-out;
  z-index: 20;
}

.modal-header {
  position: relative;
  height: 40px;
  background: $gradient-dark-gray;
  opacity: 0.85;
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
    background: url('https://api.iconify.design/material-symbols/close-rounded.svg?color=%23e07000&width=24&height=24')
      no-repeat center center / contain;
    height: 24px;
    width: 24px;
  }
}

.modal-body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &-icon {
    background: url('https://api.iconify.design/icon-park-outline/email-successfully.svg?color=%23e07000&width=64&height=64')
      no-repeat center center / contain;
    height: 64px;
    width: 64px;
  }
  &-message {
    position: relative;
    padding: 0.75rem;
    text-align: center;
    h3 {
      font-size: 1rem;
    }
    p {
      padding-top: 0.5rem;
      font-size: 0.75rem;
    }
  }
}

@media (min-width: 30em) {
  .modal-content {
    &-message {
      h3 {
        font-size: 1.25rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}

@media (min-width: 50em) {
  .modal-container {
    width: 640px;
  }
}
