@import './variables';

.move-to-icon {
  position: absolute;
  opacity: 0;
  right: 16px;
  bottom: 8px;
  transition: all 0.75s ease-in-out;
  display: none;
}

@media (min-width: 30em) {
  .move-to-icon {
    display: block;
  }
}
