@import '../../../styles/variables';

.primary-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: $darker-gray-opacity;
  z-index: 100;
  box-shadow: 0rem 4px 4px rgba(#000, 0.5);
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // for logo \/

    a:first-of-type {
      text-decoration: none;
    }
    &-branding {
      height: 40px;
      width: 360px;
    }
    &-items {
      position: absolute;
      top: 48px;
      display: flex;
      flex-direction: column;
      background: #fff;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(-100%);
      transition: all 0.45s;
      gap: 16px;
      padding: 16px;
      list-style: none;
      a {
        font-weight: 500;
        font-size: 16px;
        text-decoration: none;
        position: relative;
        opacity: 0.8;
        color: black;
        &:before {
          background: transparent;
        }
      }
      &.open {
        transform: translateX(0);
      }
    }
    &-toggle {
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .bar {
        position: relative;
        width: 32px;
        height: 2px;
        background: #ffffff;
        transition: all 0.45s ease-in-out;
        &::before,
        &::after {
          content: '';
          position: absolute;
          height: 2px;
          background: #ffffff;
          border-radius: 2px;
          transition: all 0.45s ease-in-out;
        }
        &::before {
          width: 25px;
          transform: translateY(-8px);
          right: 0;
        }
        &::after {
          width: 32px;
          transform: translateY(8px);
        }
      }
      &.open .bar {
        transform: translateX(-40px);
        background: transparent;
        &::before {
          width: 32px;
          transform: rotate(45deg) translate(26px, -26px);
        }
        &::after {
          transform: rotate(-45deg) translate(26px, 26px);
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .primary-header {
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      margin: 0 auto;
      &-items {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        background: transparent;
        transform: translateX(0);
        flex-direction: row;
        height: 100%;
        top: 0;
        gap: 18px;

        a {
          color: white;
          font-size: 14px;
          &:after {
            transition: all 0.75s ease-in-out;
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: auto;
            width: 0%;
            height: 1px;
            content: '.';
            color: transparent;
            background: rgb(38, 38, 38);
            background: linear-gradient(
              90deg,
              rgba(38, 38, 38, 1) 0%,
              rgba(255, 159, 38, 1) 50%,
              rgba(38, 38, 38, 1) 100%
            );
          }
          &:hover {
            opacity: 1;
            color: #e8eaed;
            transform: translateY(8px);
            &:after {
              width: 90%;
              height: 2px;
            }
          }
        }
      }
    }
    .nav-toggle {
      display: none;
    }
    .nav-items a:last-of-type {
      &::after {
        display: none;
      }
    }
  }
  .button-cta {
    height: 40px;
    padding: 12px 24px;
    background: $gradient-color;
    border-radius: 16px 0 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    color: $lighterGray;
    box-shadow: $primary-shadow;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    position: relative;
    z-index: 2;

    &:hover {
      // animation-name: bounce;
      // animation-timing-function: ease;
    }
  }
  a:last-of-type + .camper {
    display: none;
  }
}

@media (min-width: 992px) {
  .primary-header {
    .nav {
      &-items {
        gap: 28px;
        padding: 0;
        a {
          font-size: 16px;
        }
        a:last-of-type {
          position: relative;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .primary-header {
    height: 72px;
    .nav {
      &-branding {
        width: 300px;
        height: 48px;
      }
      &-items {
        gap: 32px;
        a {
          font-size: 20px;
        }
      }
    }
    .button-cta {
      height: 48px;
      z-index: 10;
      // z-index: 10;
      // &::before {
      //   width: 20px;
      //   height: 20px;
      //   background-color: $lightGray;
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // } before after kółko na hover?
    }
  }

  .button-cta:hover + .camper {
    display: block;
    animation: trackanimation 6s linear infinite;
  }
}

.camper-anim {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: $gradient-orange-cta;
  height: 100%;
  border-radius: 20px 20px 10px 5px;
  opacity: 0.2;
  z-index: 1;
  &::before {
    content: url('https://api.iconify.design/solar/wheel-linear.svg?color=white&width=24&height=24');
    position: absolute;
    bottom: -8px;
    left: 8px;
    height: 24px;
    width: 24px;
    line-height: 1;
  }
  &::after {
    content: url('https://api.iconify.design/solar/wheel-linear.svg?color=white&width=24&height=24');
    position: absolute;
    bottom: -8px;
    right: 8px;
    height: 24px;
    width: 24px;
    line-height: 1;
  }
  animation: trackanimation 6s linear infinite;
  &::before {
    animation: wheelanimation linear 0.6s infinite;
  }
  .camper-anim__window {
    width: 44px;
    height: 16px;
    background-color: $white;
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 8px 12px 2px 2px;
  }
}

.button-cta:hover ~ .camper-anim {
  display: block;
}

@keyframes bounce {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0);
  }
}
/* Rotation of the wheels */

@keyframes trackanimation {
  100% {
    transform: translate(500px);
    opacity: 0;
  }
}
